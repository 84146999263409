import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/webpack-export-modules",
  "date": "2016-06-22",
  "title": "WEBPACK EXPORT MODULES",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "You may be working in a complex project, where multiple isolated modules need to be imported into a single parent module, you might want to configure Webpack.config to enable exporting modules."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Maybe you have developed a library and want to distribute it in bundled versions. You want to allow the user to use it in a `}<inlineCode parentName="p">{`require`}</inlineCode>{` or `}<inlineCode parentName="p">{`import`}</inlineCode>{` statement. Or you depend on various precompilations and want to remove the pain for the user and distribute it as simple compiled CommonJS module.`}</p>
    <p>{`Either scenario, this article will help you to configure your `}<inlineCode parentName="p">{`Webpack`}</inlineCode>{` configurattion.`}</p>
    <h2>{`Configuration Options`}</h2>
    <p>{`Webpack has three configuration options to help you out for these use cases:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`output.library`}</inlineCode>{` – Specify a name for your library`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`output.libraryTarget`}</inlineCode>{` – Type of output ie. CommonJS, AMD, UMD`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`externals`}</inlineCode>{` – Dependencies for your libraries, to be imported from the environment at runtime`}</li>
    </ul>
    <h2>{`Webpack Example Module`}</h2>
    <p>{`Let’s start off by creating a React component at the `}<inlineCode parentName="p">{`src`}</inlineCode>{` directory and name it `}<inlineCode parentName="p">{`index.js`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
 
export default const MyApp = () => {
  return (
    <div>
      <h1>My App</h1>
      <p>Hello world!</p>
    </div>
  );
};
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file will look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "name": "webpack-export-module-demo",
  "version": "1.0.0",
  "description": "Webpack demo for exporting a module",
  "main": "index.js",
  "scripts": {
    "build:webpack": "webpack --config webpack.config.js",
    "build": "npm run build:webpack",
    "prepublish": "npm run build"
  },
  "keywords": [
    "Webpack"
  ],
  "author": "Sean Amarasinghe",
  "license": "ISC",
  "dependencies": {
    "react": "^15.1.0"
  }
}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`webpack.config.js`}</inlineCode>{` file will have `}<inlineCode parentName="p">{`library`}</inlineCode>{` and `}<inlineCode parentName="p">{`libraryTarget`}</inlineCode>{` properties.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var webpack = require('webpack');
var UglifyJsPlugin = require('webpack/lib/optimize/UglifyJsPlugin');
 
module.exports = {
    context: __dirname + '/src/',
    entry: './index.js',
    output: {
        path: __dirname + '/dist/',
        filename: 'bundle.js',
        library: 'my-app',
        libraryTarget: 'commonjs2'
    },
    module: {
        loaders: [
            {
              test: /\\.js$/,
              loader: 'babel-loader'
            }
        ]
    },
    externals: {
        react: {
            root: 'React',
            commonjs: 'react',
            commonjs2: 'react',
            amd: 'react'
        }
    },
 
    plugins: [
        new UglifyJsPlugin()
    ]
};
`}</code></pre>
    <p>{`We provided a name for the library, `}<inlineCode parentName="p">{`my-app`}</inlineCode>{` and the libraryTarget `}<inlineCode parentName="p">{`commonjs2`}</inlineCode>{`. If you selected `}<inlineCode parentName="p">{`commonjs`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`commonjs2`}</inlineCode>{`, you will have `}<inlineCode parentName="p">{`default`}</inlineCode>{` as the container object, which you might not want.`}</p>
    <p>{`Now you can import this library into another project.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`You can find the source code for this article in `}<a parentName="p" {...{
        "href": "https://github.com/szaranger/webpack-export-module-demo"
      }}>{`Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      